import Vue from "vue";
import Vuex from "vuex";
import { isArrayEmpty, generateCombinations } from "@/utils/utils";

Vue.use(Vuex);

const isStrEmpty = (str: string): boolean => {
    return !str || str.length === 0 || str === "undefined";
};

const getUserInfo = () => {
    return localStorage.getItem("user_info") || "";
};

export default new Vuex.Store({
    state: {
        userInfo: !isStrEmpty(getUserInfo()) ? JSON.parse(getUserInfo()) : null,
        tokenV3: localStorage.getItem("access_tokenv3"),
        cart: [],
        cartRender: [],
        quantity: 0,
        transact459: [],
        transact545: [],
        transactPariLotto: [],
        transact: [],
        enabled_game_slugs: "",
        reprintCountToShowCancel: 1,
        milisecondsToForceFinishPrinting: 2000,
        networkTimeoutSeconds: 30,
        isShowSports: true,
        isOnlyPayment: true,
        isShow645: true,
        isShow590: true,
        isShow459: true,
        isShow545: true,
        isShowVoucher: true,
        isLoadingAddCart: false,
        totalCart: 0,
        dataGamesPari: [],
        stepCart: 1,
    },
    getters: {},
    mutations: {
        SET_USER_INFO(state, data) {
            state.userInfo = data;
        },

        REMOVE_USER_INFO(state) {
            state.userInfo = null;
            localStorage.removeItem("user_info");
        },

        SET_TOKEN_V3(state, data) {
            state.tokenV3 = data;
        },

        SET_DATA_CART(state, data) {
            state.cart = data.cart
            state.cartRender = data.cartRender
            state.quantity = data.quantity
            state.transact = data.transact
            state.totalCart = data.totalCart
            state.transactPariLotto = data.transactPariLotto
            state.transact459 = data.transact459
            state.transact545 = data.transact545

            localStorage.setItem('transactPariLotto', JSON.stringify(data.transactPariLotto))
            localStorage.setItem('transact459', JSON.stringify(data.transact459))
            localStorage.setItem('transact545', JSON.stringify(data.transact545))
            localStorage.setItem('cartRender', JSON.stringify(data.cartRender ? data.cartRender : []))
            localStorage.setItem('cart', JSON.stringify(data.cart))
            localStorage.setItem('quantity', data.quantity)
            localStorage.setItem('transact', JSON.stringify(data.transact))
            localStorage.setItem('totalCart', JSON.stringify(data.totalCart))
            state.isLoadingAddCart = false
        },
        SET_DATA_CART_SWITCH(state, data) {
            state.totalCart = data.totalCart;
            state.cartRender = data.cartRender;
            state.stepCart = data.step;
            localStorage.setItem("totalCart", JSON.stringify(data.totalCart));
            localStorage.setItem("cartRender", JSON.stringify(data.cartRender));
            localStorage.setItem("stepCart", JSON.stringify(data.step));
        },
    },
    actions: {
        handleUserInfo({ commit, state }, data) {
            commit("SET_USER_INFO", data);
        },

        removeUserInfo({ commit }) {
            commit("REMOVE_USER_INFO");
        },

        setTokenV3({ commit, state }, data) {
            commit("SET_TOKEN_V3", data);
        },

        onSwitchBetslip({ commit, state }, payload) {
            const { step } = payload
            const cart: any = state.cart
            let type = 'Loto 4/59'

            if (step === 1) {
                type = 'loto-590'
            } else if (step === 2) {
                type = 'lotto-6-45'
            } else if (step === 3) {
                type = 'loto-4-59'
            } else {
                type = 'lotto-5-45'
            }
            
            let cartRender = cart.filter((x) => x.gameType === type)
            let totalCart = cartRender.reduce((a, b) => a + b.price, 0)
            const param = {
                totalCart,
                cartRender,
                step,
            };

            commit("SET_DATA_CART_SWITCH", param);
        },

        onAddToCart({ commit, state }, payload) {
            const pariMutuelLotto = ['loto-4-59', 'lotto-6-45', 'lotto-5-45']

            const cart: any = state.cart
            let cartRender: any = []
            const transact: any = state.transact
            const transactPariLotto: any = state.transactPariLotto
            const transact459: any = state.transact459
            const transact545: any = state.transact545
            let quantity: number = state.quantity
            let totalCart: number = 0
            let title = `${payload.title}`
            let game = `${payload.game}`
            let quantityBets = 1

            if (!pariMutuelLotto.includes(payload.gameType)) {
                title += ` ${payload.gameNumber}`
                game += `${payload.gameNumber}`
            }

            payload.event_id.map((eventId, i) => {
                let item = {
                    id: new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g, ""),
                    parentId: payload.id,
                    eventId,
                    eventDate: payload.eventInfo[i].date,
                    eventName: payload.eventInfo[i].name,
                    selection: [payload.selection],
                    title,
                    price: payload.wager,
                    bets: 0,
                    gameType: payload.gameType,
                    isPariMutuelLotto: payload.isPariMutuelLotto,
                }

                if (payload.isPariMutuelLotto && payload.selection.length > payload.numberQp) {
                    quantityBets = generateCombinations(Number(payload.selection.length), Number(payload.numberQp));
                    item["price"] = payload.wager * quantityBets;
                    item["bets"] = quantityBets;
                }

                if (payload.gameType === 'loto-4-59') item['isPariMutuelLotto'] = false
                cart.push(item)
            })

            let item = {
                id: payload.id,
                selection: [payload.selection],
                draws: payload.draws,
                event_id: payload.event_id,
                wager: payload.wager,
                game,
            };

            if (payload.gameType === 'lotto-6-45') {
                transactPariLotto.push(item)
            } else if (payload.gameType === 'loto-4-59') {
                transact459.push(item)
            } else if (payload.gameType === 'lotto-5-45') {
                transact545.push(item)
            } else {
                transact.push(item)
            }

            cartRender = cart.filter((x) => x.gameType)
            totalCart = cartRender.reduce((a, b) => a + b.price, 0)
            quantity = cart.length

            const param = {
                quantity,
                transact,
                cart,
                totalCart,
                transactPariLotto,
                transact459,
                transact545,
                cartRender,
            };

            commit("SET_DATA_CART", param);
        },
        onRemoveItemCart({ commit, state }, payload) {
            const cart: any = state.cart
            let cartRender: any = []
            let transact: any = state.transact
            let transactPariLotto: any = state.transactPariLotto
            let transact459: any = state.transact459
            let transact545: any = state.transact545
            let quantity: number = state.quantity
            let totalCart: number = state.totalCart
            const element = payload.item

            let transactClone: any = []

            if (element.gameType === 'lotto-6-45') {
                transactClone = transactPariLotto
            } else if (element.gameType === 'loto-4-59') {
                transactClone = transact459
            } else if (element.gameType === 'lotto-5-45') {
                transactClone = transact545
            } else {
                transactClone = transact
            }

            const index = transactClone.findIndex((x) => x.id === element.parentId);

            if (index !== -1) {
                transactClone[index].event_id = transactClone[index].event_id.filter((x) => x !== element.eventId);
            }

            if (isArrayEmpty(transactClone[index]?.event_id)) {
                transactClone.splice(index, 1);
            }

            const indexCart = cart.findIndex((x) => x.id === element.id);

            cart.splice(indexCart, 1);

            cartRender = cart.filter((x) => x.gameType === element.gameType)

            totalCart = cartRender.reduce((a, b) => a + b.price, 0)
            quantity = cart.length
            let param = {
                quantity,
                transact,
                cart,
                totalCart,
                transact459,
                transact545,
                transactPariLotto,
                cartRender,
            };

            if (element.gameType === 'lotto-6-45') {
                param['transactPariLotto'] = transactClone
            } else if (element.gameType === 'loto-4-59') {
                param['transact459'] = transactClone
            } else if (element.gameType === 'lotto-5-45') {
                param['transact545'] = transactClone
            } else {
                param["transact"] = transactClone;
            }

            commit("SET_DATA_CART", param);
        },

        onClearCart({ state, commit }) {
            let cart: any = state.cart

            let type = 'loto-4-59'

            if (state.stepCart === 1) {
                type = 'loto-590'
            } else if (state.stepCart === 2) {
                type = 'lotto-6-45'
            } else if (state.stepCart === 3) {
                type = 'loto-4-59'
            } else {
                type = 'lotto-5-45'
            }

            cart = cart.filter((x) => x.gameType !== type)
            let totalCart = cart.reduce((a, b) => a + b.price, 0)
            let quantity = cart.length

            let param = {
                quantity,
                transact: state.stepCart === 1 ? [] : state.transact,
                cart: cart,
                totalCart,
                transactPariLotto: state.stepCart === 2 ? [] : state.transactPariLotto,
                transact459: state.stepCart === 3 ? [] : state.transact459,
                transact545: state.stepCart === 4 ? [] : state.transact545,
                cartRender: [],
            };
            commit("SET_DATA_CART", param);
        },

        onClearCartAll({commit}) {
            let param = {
                quantity: [],
                transact: [],
                cart: [],
                totalCart: 0,
                transactPariLotto: [],
                transact459: [],
                transact545: [],
                cartRender: [],
            }
            commit('SET_DATA_CART', param)
        },
    },
});

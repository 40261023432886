<template>
    <v-app v-if="!isLoadingSettings">
        <div class="version-update" v-if="isCheckUpdateVersion">
            <p>{{ getTranslatedText(`You're using old version, please upgrade new version.`) }}</p>
        </div>
        <template v-else>
            <main class="main-container" :class="{ 'no-padding': this.$route.meta.isNotPadding }">
                <router-view />
            </main>
            <Notications />
            <DialogComponent />
            <Navigation v-if="this.$route.meta.isShowMenu" />
        </template>
    </v-app>
</template>
<script>
import Navigation from '@/components/navigation/index.vue'
import Notications from '@/components/common/notications/index.vue'
import DialogComponent from '@/components/common/dialog/index.vue'
import AppMixins from '@/mixins/app/app-mixin'
import EventBus from './utils/EventBus'
import languageMixins from '@/mixins/language/language-mixins'
import Vue from 'vue'

import { version } from "../package.json";
import { clearLocationOfDevice, checkCompatibility, getParamQueryTheme, isStrEmpty, setSettingDevice } from "./utils/utils";

export default {
    mixins: [languageMixins, AppMixins],
    components: {
        Notications,
        DialogComponent,
        Navigation,
    },
    data() {
        return {
            isCheckUpdateVersion: false,
            isLoadingSettings: true,
        };
    },
    mounted() {
        this.fetchData();
        if (!isStrEmpty(getParamQueryTheme("userid"))) {
            localStorage.setItem("userid", getParamQueryTheme("userid"));
        }
        if (!window.ReactNativeWebView) {
            this.isLoadingSettings = false;
        }

        document.addEventListener("message", this.handleMessage);
        setInterval(function () {
            parent.postMessage({ command: 'get-location' }, '*')
        }, 5000);
    },
    beforeDestroy() {
        document.removeEventListener("message", this.handleMessage);
    },
    methods: {
        fetchData() {
            const cart = !isStrEmpty(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : []
            const cartRender = !isStrEmpty(localStorage.getItem('cartRender')) ? JSON.parse(localStorage.getItem('cartRender')) : []
            const quantity = !isStrEmpty(localStorage.getItem('quantity')) ? localStorage.getItem('quantity') : 0
            const totalCart = !isStrEmpty(localStorage.getItem('totalCart')) ? localStorage.getItem('totalCart') : 0
            const transact = !isStrEmpty(localStorage.getItem('transact')) ? JSON.parse(localStorage.getItem('transact')) : []
            const transact459 = !isStrEmpty(localStorage.getItem('transact459')) ? JSON.parse(localStorage.getItem('transact459')) : []
            const transact545 = !isStrEmpty(localStorage.getItem('transact545')) ? JSON.parse(localStorage.getItem('transact545')) : []
            const transactPariLotto = !isStrEmpty(localStorage.getItem('transactPariLotto')) ? JSON.parse(localStorage.getItem('transactPariLotto')) : []
            const stepCart = !isStrEmpty(localStorage.getItem('stepCart')) ? JSON.parse(localStorage.getItem('stepCart')) : 1
            this.$store.state.cart = cart
            this.$store.state.cartRender = cartRender || []
            this.$store.state.transact = transact
            this.$store.state.transactPariLotto = transactPariLotto
            this.$store.state.transact459 = transact459
            this.$store.state.transact545 = transact545
            this.$store.state.quantity = parseInt(quantity)
            this.$store.state.totalCart = parseInt(totalCart)
            this.$store.state.stepCart = parseInt(stepCart)
        },
        handleMessage(event) {
            const resp = JSON.parse(event.data)
            // donothing if reprint dialog is showing
            if (resp.command === 'go-back' && Vue.prototype.$isReprintDialogShowing !== true) {
                if (this.$route.name.toLowerCase() === 'home page') {
                    parent.postMessage({ command: 'on-confirm-out-app' }, '*')
                } else if (this.$route.name.toLowerCase() === 'loto') {
                    EventBus.$emit('on-handle-back-app')
                } else {
                    this.$router.go(-1);
                }
            }
            if (resp.command === "device-info") {
                const data = resp.data;
                data.position = resp.position
                setSettingDevice(data);
                this.isCheckUpdateVersion = checkCompatibility(data.appVersion, version);
                this.isLoadingSettings = false;
            }
            if (resp.command === 'no-location-permission') {
                const param = {
                    text: 'Location Permission is not allowed! App will work as normal for now',
                    type: 'warning',
                }

                // show warning 1 time
                if (!localStorage.getItem('location_permission_waring_count')) {
                    localStorage.setItem('location_permission_waring_count', 1)
                    EventBus.$emit('show-notications', param)
                }

                // clear location
                clearLocationOfDevice('no-location-permission')
            }
            if (resp.command === 'location-error') {
                const err = resp.error
                const param = {
                    text: 'Error when getting location: ' + err.message,
                    type: 'warning',
                }

                // show warning 1 time
                // EventBus.$emit('show-notications', param)

                // clear location
                clearLocationOfDevice('location-error')
            }
        },
    },
};
</script>

<style lang="scss">
@import "~ThemePath/scss/main.scss";
</style>
